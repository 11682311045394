import React, { Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, connect, GLGlobal } from 'gl-commonui';
import { SecondaryTitle } from '@app/components';
import { GlobalProductDetail } from './components/global-detail';
import { StateType } from '@app/states';
import { getContentVersions, createGlobalProduct, goToGlobalProductList } from '@app/states/admin/product';
import { ContentVersionModel } from '@app/service/admin/contentversion';
import { GSAdminLocale } from '@app/locales/localeid';

interface GlobalProductNewPageProps {
    contentVersions: ContentVersionModel[]
    getContentVersions: () => void
    createGlobalProduct: (product) => void
    goToGlobalProductList: () => void
}

@withRouter
@connect(({ product: { contentVersions } }: StateType) => ({
    contentVersions,
}), {
        getContentVersions,
        createGlobalProduct,
        goToGlobalProductList
    })
export class GlobalProductNewPage extends Component<RouteComponentProps<any> & GlobalProductNewPageProps> {
    componentDidMount() {
        this.props.getContentVersions();
    }
    render() {
        const { contentVersions, createGlobalProduct, goToGlobalProductList } = this.props;
        return (
            <div className='content-layout'>
                <div className="product-detail product-detail-edit">
                    <SecondaryTitle title={GSAdminLocale.LocalProductNewTitle} />
                    <GlobalProductDetail
                        contentVersions = {contentVersions}
                        handleSubmit={(product) => createGlobalProduct(product)} 
                        goToList={goToGlobalProductList}
                    />
                </div>
            </div>
        )
    }
}